















































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',')
    const mimes = arr[0].match(/:(.*?);/)
    const mime = mimes ? mimes[1] : undefined
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
        
    while (n--) u8arr[n] = bstr.charCodeAt(n)
    
    return new File([u8arr], filename, { type: mime })
}

export default defineComponent({
  props: {
    again: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(_, { root, emit }) {
    const canvas = ref<null | HTMLCanvasElement>(null)
    const video = ref<null | HTMLVideoElement>(null)
    const input = ref<null | HTMLInputElement>(null)

    const state = reactive({
      dialog: false,
      loading: false,
      error: false as boolean | string,
      devices: null as null | MediaDeviceInfo[],
      mode: 'environment' as 'environment' | 'user',
      stream: null as null | MediaStream,
      image: null as null | string,

      width: 1000,
      height: 0,

      scanning: false,
      light: false,

      fileInputKey: 0
    })

    const clearCanvas = () => {
      if (canvas.value) {
        const context = canvas.value.getContext('2d')
        context?.clearRect(0, 0, canvas.value.width, canvas.value.height)
      }
    }

    const drawSquare = () => {
      if (canvas.value) {
        const context = canvas.value.getContext('2d')
        const cw = canvas.value.width
        const ch = canvas.value.height

        const size = Math.max(1000, Math.min(cw, ch))

        if (context) {
          const sw = size - 500
          const sh = size - 500

          const x = cw / 2 - sw / 2
          const y = ch / 2 - sh / 2

          context.strokeStyle = '#FF0000'
          context.lineWidth = 5
          context.strokeRect(x, y, sw, sh)

          context.fillStyle = 'red'
          context.textAlign = 'center'
          context.font = '32px Roboto'
          context.fillText('UMIEŚĆ KOD AZTEC W RAMCE', x + sw / 2, y - 64, sw)
        }
      }
    }

    const resetCanvas = () => {
      clearCanvas()
      drawSquare()
    }

    const updateLight = () => {
      state.stream?.getTracks().forEach(track => {
        track.applyConstraints({ advanced: [{ torch: state.light } as any]})
      })
    }

    const build = async () => {
      if ('mediaDevices' in navigator) {
          await navigator.mediaDevices
            .enumerateDevices()
            .then(devices => state.devices = devices.filter(device => device.kind == 'videoinput'))
            .catch(() => {
              state.error = 'Nie udało się pobrać listy dostępnych urządzeń video.'
              console.log('Nie udało się pobrać listy urządzeń')
            })

          if (state.devices) {
            const constraints: MediaStreamConstraints = {
              video: {
                facingMode: state.mode,
                width: { ideal: 1000 },
              }
            }

            await navigator.mediaDevices
              .getUserMedia(constraints)
              .then(async stream => {
                state.stream = stream
                updateLight()

                if (video.value) {
                  video.value.setAttribute('autoplay', '')
                  video.value.setAttribute('muted', '')
                  video.value.setAttribute('playsinline', '')

                  video.value.srcObject = stream
                  await video.value.play()
                  state.height = video.value.videoHeight /  (video.value.videoWidth / state.width)

                  video.value.setAttribute('width', state.width.toString())
                  video.value.setAttribute('height', state.height.toString())

                  if (canvas.value) {
                    canvas.value.setAttribute('width', state.width.toString())
                    canvas.value.setAttribute('height', state.height.toString())
                    resetCanvas()
                  }
                } else {
                  state.error = 'Nie udało się połączyć z interfejsem kamery.'
                  console.log('Nie udało się wyrenderować obrazu z kamery')
                }
              })
          } else {
            state.error = 'Nie znaleziono żadnego interfejsu video w urządzeniu.'
            console.log('Nie znaleziono kamery')
          }
      } else {
        state.error = 'To urządzenie nie obsługuje interfejsu kamery.'
        console.log('To urządzenie nie obsługuje interfejsu kamery')
      }
    }

    const destroy = async () => {
      updateLight()
      clearCanvas()
      state.stream?.getTracks().forEach(track => track.stop())

      state.devices = null
      state.stream = null
      state.image = null
      state.error = false
      state.light = false
    }

    const open = () => {
      build()
      state.dialog = true
    }

    const close = () => {
      destroy()
      state.dialog = false
    }

    const restart = () => {
      close()
      open()
    }

    const switchDevice = async () => {
      if (state.mode == 'environment') state.mode = 'user'
      else state.mode = 'environment'

      destroy()
      build()
    }

    const scan = () => {
      if (state.image) {
        emit('start-loading')
        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

        const data = new FormData()

        data.append('aztec', dataURLtoFile(state.image, 'aztec.png'))

        axiosInstance
          .post('scanner', data)
          .then(({ data }) => {
            emit('input', data)
            close()
          })
          .catch(() => {
            state.error = 'Nie udało się zdekodować danych z kodu Aztec. Postaraj się, aby obraz był wyraźny, a kod Aztec czytelny.'
            console.log('Nie udało się odczytać kodu')
          })
          .finally(() => {
            state.image = null
            state.scanning = false
            emit('stop-loading')
          })
      } else {
        alert('error')
      }
    }

    const capture = () => {
      state.scanning = true

      if (canvas.value && video.value) {
        clearCanvas()
        const context = canvas.value.getContext('2d')

        const fakeCanvas = document.createElement('canvas')
        const fakeContext = fakeCanvas.getContext('2d')

        const cw = canvas.value.width
        const ch = canvas.value.height

        const size = Math.max(1000, Math.min(cw, ch))

        if (context && fakeContext) {
          const sw = size - 500
          const sh = size - 500

          const x = cw / 2 - sw / 2
          const y = ch / 2 - sh / 2

          fakeCanvas.width = sw
          fakeCanvas.height = sh

          context.drawImage(video.value, 0, 0, cw, ch)

          fakeContext.filter = 'grayscale(100%) contrast(1000%)'
          fakeContext.drawImage(canvas.value, x, y, sw, sh, 0, 0, sw, sh)

          state.image = fakeCanvas.toDataURL('image/png')
          state.stream?.getTracks().forEach(track => track.stop())

          scan()
        }
      }
    }

    const upload = () => {
      if (input.value) {
        input.value.click()
      }
    }

    const onFileInput = async (event: Event) => {
      const el = event.target as HTMLInputElement

      if (el.files?.length) {
        const file = el.files[0]
        if (['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            state.image = reader.result as string
            state.fileInputKey += 1
            scan()
          })
          reader.readAsDataURL(file)
        } else {
          state.error = 'Podano niepoprawny format pliku, dozwolone są wyłącznie pliki PNG i JPG/JPEG.'
          console.log('Niepoprawny typ pliku')
        }
      }
    }

    const switchLight = () => {
      state.light = !state.light
      updateLight()
    }

    return { input, canvas, video, state, switchDevice, open, close, restart, capture, upload, onFileInput, switchLight }
  }
})
